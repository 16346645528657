var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _c(
        "div",
        { attrs: { id: "layerPop" } },
        [
          _c(
            "win-layer-pop",
            [
              _vm.customComponent
                ? _c(_vm.customComponent, {
                    tag: "component",
                    attrs: { detail: _vm.detail, "ok-hidden": _vm.isHidden },
                    on: { close: _vm.closePoup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU04.020"))),
      ]),
      _c("form", { attrs: { id: "frm" } }, [
        _c("div", { staticClass: "content_box pb15" }, [
          _c("table", { staticClass: "tbl_search" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.001")))]),
                _vm._v(" "),
                _c("td", { staticClass: "pr0" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.param.asDtKnd,
                          expression: "param.asDtKnd",
                        },
                      ],
                      staticClass: "wid150",
                      staticStyle: { width: "150px" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.param,
                              "asDtKnd",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.optionChange()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "RQ" } }, [
                        _vm._v("Request No."),
                      ]),
                      _c("option", { attrs: { value: "DT" } }, [
                        _vm._v("Request Date"),
                      ]),
                    ]
                  ),
                ]),
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm.param.asDtKnd === "RQ"
                    ? _c("div", [
                        _c("span", { staticClass: "form_tit" }),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.trim",
                              value: _vm.param.asReqNo1,
                              expression: "param.asReqNo1",
                              modifiers: { trim: true },
                            },
                          ],
                          staticStyle: { width: "150px" },
                          attrs: { type: "text" },
                          domProps: { value: _vm.param.asReqNo1 },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.param,
                                "asReqNo1",
                                $event.target.value.trim()
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.param.asDtKnd === "DT"
                    ? _c(
                        "div",
                        [
                          _c("e-date-range-picker", {
                            attrs: {
                              id: "fromDtToDt",
                              sdate: _vm.dateFrom,
                              edate: _vm.dateTo,
                            },
                            on: { change: _vm.changeDateRange },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("td", { staticClass: "text_right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button blue sh",
                      on: {
                        click: function ($event) {
                          return _vm.searchData()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00120")) + " ")]
                  ),
                ]),
              ]),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validationDt,
                      expression: "validationDt",
                    },
                  ],
                  staticStyle: { height: "15px" },
                },
                [_c("td")]
              ),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.006")))]),
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "polPol",
                        "ctr-cd": _vm.pol.ctrCd,
                        "plc-cd": _vm.pol.plcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePol },
                    }),
                  ],
                  1
                ),
                _c("th", [
                  _c("span", { staticClass: "ml20" }, [
                    _vm._v(_vm._s(_vm.$t("msg.SPEC030.007"))),
                  ]),
                ]),
                _c(
                  "td",
                  { attrs: { colspan: "2" } },
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podPod",
                        "ctr-cd": _vm.pod.ctrCd,
                        "plc-cd": _vm.pod.plcCd,
                        "is-char": true,
                        "is-char-alert": true,
                      },
                      on: { change: _vm.changePod },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("td", { staticClass: "pl0", attrs: { colspan: "6" } }, [
                  _c("div", [
                    _c("span", { staticClass: "ml10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.apvStsCd,
                            expression: "param.apvStsCd",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chk1",
                          name: "chk1",
                          value: "02",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.param.apvStsCd)
                            ? _vm._i(_vm.param.apvStsCd, "02") > -1
                            : _vm.param.apvStsCd,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.param.apvStsCd,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "02",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.param,
                                    "apvStsCd",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.param,
                                    "apvStsCd",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.param, "apvStsCd", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "chk1" } }, [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.SPEC030.002"))),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.param.apvStsCd,
                            expression: "param.apvStsCd",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "chk2",
                          name: "chk2",
                          value: "03",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.param.apvStsCd)
                            ? _vm._i(_vm.param.apvStsCd, "03") > -1
                            : _vm.param.apvStsCd,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.param.apvStsCd,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "03",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.param,
                                    "apvStsCd",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.param,
                                    "apvStsCd",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.param, "apvStsCd", $$c)
                            }
                          },
                        },
                      }),
                      _c("label", { attrs: { for: "chk2" } }, [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.SPEC030.003"))),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "tot_num" }, [
            _vm._v("Total : "),
            _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.total))]),
          ]),
          _c("span", { staticClass: "input_box" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.templateStr,
                  expression: "templateStr",
                },
              ],
              staticClass: "wid300",
              attrs: {
                type: "text",
                placeholder: _vm.$t("msg.SPEC010G010.000"),
              },
              domProps: { value: _vm.templateStr },
              on: {
                keyup: _vm.templateEnter,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.templateStr = $event.target.value
                },
              },
            }),
          ]),
          _c("span", { staticClass: "ml_auto cal_btn" }, [
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          staticStyle: { width: "100%", height: "450px" },
          attrs: { id: "realgrid" },
        }),
      ]),
      _c("div", { staticClass: "flex_box mt10" }, [
        _c(
          "a",
          {
            staticClass: "button lg",
            on: {
              click: function ($event) {
                return _vm.openPopup(
                  "oogDetailPop",
                  "btnClickDetail",
                  _vm.btnReqNo
                )
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.SPEC020G010.000")))]
        ),
        _c(
          "a",
          {
            staticClass: "button blue lg ml_auto",
            on: {
              click: function ($event) {
                return _vm.openPopup("OogDgInquiryPop", "btnClickInquiry")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("msg.CSBK140.005")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "70px" } }),
      _c("col", { attrs: { width: "152px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "110px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }